import React from "react";
import Photo1 from "../images/orchard.jpeg";
import Photo4 from "../images/orchard1.jpg";
import Photo5 from "../images/orchard2.jpg";
import Photo6 from "../images/orchard3.jpg";
import BeePhoto from "../images/bees.jpg";

function Orchard() {
  return (
    <div className="orchard">
      <div className="container scroll">
        {/* Header Section */}
        <section className="hero-section text-center">
          <h1>Chinbrook Community Orchard</h1>
        </section>

        {/* Orchard Description Section */}
        <section className="orchard-description">
          <div className="text-center">
            <img
              src={Photo1}
              alt="orchard"
              className="orchard-photo img-fluid rounded shadow mb-4"
              style={{
                maxWidth: "70%",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "0 auto",
                display: "block",
              }}
            />
          </div>
          <p>
            Planted in 1991 by Lewisham’s Nature Conservation Team, the orchard
            is designated as a Grade II Site of Borough Importance for Nature
            Conservation. Most of the trees are traditional (mostly Kentish)
            varieties of fruit trees, including cobnuts, plums, pears, cherries,
            walnuts, and no fewer than 30 varieties of apples. Their names are
            wonderfully poetic – ‘Kentish Filbasket’, ‘Beauty of Kent’, ‘Decio’,
            ‘Allington Pippin’. A few more exotic species, including a cork oak,
            olives, walnuts, and stone pines, were also planted to reflect the
            borough’s ethnic diversity as well as a small demonstration
            vineyard.
          </p>
          <p>
            The grass between the fruit trees is managed as a hay meadow and cut
            rotationally every year to provide a habitat for wood mice, common
            lizards, slow worms, and a variety of invertebrates including stag
            beetles who all benefit from the various dead wood structures
            created in the orchard. Ant hills are also present in the grassland.
            The grass vetchling, an uncommon species in Lewisham, grows in
            profusion. Currently, two allotment plot holders keep beehives in
            the orchard.
          </p>
          <p>
            Two ponds on the edge of the orchard are planted with a mix of
            native marginal and aquatic plants. Common frogs, common toads, and
            smooth newts all breed in the ponds feeding in the surrounding tall
            grassland.
          </p>
          <p>
            The orchard is managed on a day-to-day basis for the London Borough
            of Lewisham by a dedicated group of allotment plot holders with an
            Orchard Sub-committee reporting to the Allotment Committee.
          </p>
        </section>

        {/* Ongoing Projects Section */}
        <section className="projects mb-5">
          <h2 className="font-weight-bold mb-4">Ongoing Projects</h2>
          <p>
            Volunteers are currently participating in several exciting
            initiatives, such as the citizen science{" "}
            <a
              href="https://bigbutterflycount.butterfly-conservation.org/"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Big Butterfly Count
            </a>{" "}
            and{" "}
            <a
              href="https://www.nhm.ac.uk/take-part/monitor-and-encourage-nature/genepools.html"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Genepools 2023
            </a>{" "}
            projects. Additionally, we are supporting Butterfly Conservation’s{" "}
            <a
              href="https://butterfly-conservation.org/bcb"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Big City Butterflies
            </a>{" "}
            project to encourage butterflies through the creation of mini
            wildflower meadows and trialling grass cuts to enhance biodiversity.
            In the upcoming months, we will plant disease-resistant elm and
            buckthorn to further support local wildlife.
          </p>
          <div className="photo-gallery mb-4">
            <img
              src={Photo4}
              alt="orchard-plaques"
              className="img-fluid rounded mb-3"
            />
            <img
              src={Photo5}
              alt="orchard-pond"
              className="img-fluid rounded mb-3"
            />
            <img
              src={Photo6}
              alt="orchard-rainwater-harvest"
              className="img-fluid rounded mb-3"
            />
          </div>
        </section>

        {/* Slugs Section */}
        <section className="slugs">
          <h2 className="font-weight-bold">The Importance of Slugs</h2>
          <p>
            Not all slugs are destructive. Many play a vital role in breaking
            down organic matter, returning nutrients to the soil, and supporting
            biodiversity. Some species even feed on decaying matter, helping to
            maintain a healthy allotment ecosystem. To manage harmful slugs,
            techniques such as crop rotation, using barriers, and encouraging
            natural predators can be effective. Learn more from{" "}
            <a
              href="https://www.charlesdowding.co.uk/resources/slug-reduction"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Charles Dowding
            </a>{" "}
            and the{" "}
            <a
              href="https://www.rhs.org.uk/biodiversity/slugs-and-snails"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              RHS.
            </a>
          </p>
        </section>

        {/* Wasps Section */}
        <section className="wasps">
          <h2 className="font-weight-bold">The Importance of Wasps</h2>
          <p>
            Wasps are invaluable for crop health, acting as predators of pests
            such as aphids and caterpillars. They also contribute to
            pollination, often visiting flowers while searching for nectar.
            Despite their bad reputation, they are crucial for maintaining
            ecological balance. This year, a low wasp population has been
            observed, emphasising the need to support their habitat. For more
            details, visit the{" "}
            <a
              href="https://www.nhm.ac.uk/discover/what-do-wasps-do.html"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Natural History Museum.
            </a>
          </p>
        </section>

        {/* Bees Section */}
        <section className="bees">
          <h2 className="font-weight-bold">The Importance of Bees</h2>
          <p>
            Bees are essential pollinators for flowers, fruits, and vegetables.
            At Chinbrook Meadows Allotments, we have a number of honey bee hives
            in our Orchard. Find out more about planting for bees{" "}
            <a
              href="https://www.bbka.org.uk/Blogs/planting-for-bees-blog"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              here
            </a>
            .
          </p>
          <div className="photo-gallery text-center mb-4">
            <img
              src={BeePhoto}
              alt="bees"
              className="img-fluid rounded shadow-sm"
              style={{
                width: "300px",
                height: "auto",
                margin: "0 auto",
              }}
            />
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact">
          <h2 className="font-weight-bold">Contact Us</h2>
          <p>
            You can reach the orchard sub-committee via email at{" "}
            <a
              href="mailto:ccorchard@outlook.com"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              ccorchard@outlook.com.
            </a>
          </p>
        </section>
      </div>
    </div>
  );
}

export default Orchard;
