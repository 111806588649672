import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Third-party imports
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure Bootstrap JS is imported

// Custom imports
import {
  Navigation,
  Footer,
  Home,
  About,
  Location,
  Orchard,
  Links,
  Conservation,
  Media,
  Gallery,
} from "./components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css"; // Your custom styles
import "./navigation.css"; // Your Nav custom styles

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/orchard" element={<Orchard />} />
      <Route path="/conservation" element={<Conservation />} />
      <Route path="/media" element={<Media />} />
      <Route path="/location" element={<Location />} />
      <Route path="/links" element={<Links />} />
      <Route path="/gallery" element={<Gallery />} />
    </Routes>
    <Footer />
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
