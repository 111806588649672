import React from "react";
import Photo1 from "../images/entrance-sign.jpg";
import Constitution from "../images/constitution.pdf";
import Aerial1 from "../images/aerial/aerial1.jpg";
import Aerial2 from "../images/aerial/aerial2.jpg";
import Aerial3 from "../images/aerial/aerial3.jpg";
import Aerial4 from "../images/aerial/aerial4.jpg";

function About() {
  return (
    <div className="about container">
      <div className="container">
        {/* Hero Section */}
        <section className="hero-section text-center">
          <h1>About Chinbrook Meadows Horticultural Society</h1>
        </section>

        {/* What is CMHS? */}
        <section>
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2>What is Chinbrook Meadows Horticultural Society?</h2>
              <p>
                We are a constituted Society whose core purpose is the
                encouragement of the cultivation of the allotments and
                management of the Chinbrook Meadows allotment site. We have a{" "}
                <a href={Constitution} target="_blank" rel="noreferrer">
                  constitution
                </a>{" "}
                that sets out how the Society works. The rules of the Society
                and the constitution can only be changed at an Annual General
                Meeting (AGM). Everyone with a plot on our site automatically
                becomes a member of the Society and is invited to the AGM.
              </p>
              <p>
                We manage the site on behalf of the London Borough of Lewisham
                (the Council), which owns the site, but we are not part of the
                Council ourselves. We are not a charity nor a business or
                corporate organisation.
              </p>
              <p>
                The allotments sit around a small area of land that the Council
                has designated as a community orchard, but the Society and
                allotments are not themselves a community orchard.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img
                src={Photo1}
                alt="Entrance sign"
                className="img-fluid my-3 rounded"
              />
            </div>
          </div>
        </section>

        {/* The Site Section */}
        <section>
          <h2>The site</h2>
          <p>
            The Chinbrook Meadows allotment site has 71 plots. Our member
            community is a wonderfully diverse group of people from all over the
            London Borough of Lewisham.
          </p>
          <p>
            You can view current wait times for the site on the{" "}
            <a
              href="https://lewisham.gov.uk/myservices/environment/~/link.aspx?_id=21A693D64ED14F3EB2EE870B6B4B42B9&_z=z"
              target="_blank"
              rel="noreferrer"
            >
              Lewisham Council website
            </a>
            . The council's allotments and community gardens team can also be
            contacted directly at{" "}
            <a href="mailto:allotments@lewisham.gov.uk">
              allotments@lewisham.gov.uk
            </a>
            .
          </p>
          <div
            id="aerialCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={Aerial1}
                  className="d-block w-75 mx-auto aerial-photo rounded"
                  alt="Aerial view 1"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Aerial2}
                  className="d-block w-75 mx-auto aerial-photo rounded"
                  alt="Aerial view 2"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Aerial3}
                  className="d-block w-75 mx-auto aerial-photo rounded"
                  alt="Aerial view 3"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Aerial4}
                  className="d-block w-75 mx-auto aerial-photo rounded"
                  alt="Aerial view 4"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#aerialCarousel"
              data-bs-slide="prev"
            >
              &#8249;
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#aerialCarousel"
              data-bs-slide="next"
            >
              &#8250;
            </button>
          </div>
        </section>

        {/* The Committee Section */}
        <section>
          <h2>The committee</h2>
          <p>
            The Chinbrook Meadows committee members and officers are all unpaid
            volunteers who play an important role in how the site is organised
            today and what it will look like in the future.
          </p>
          <p>
            At present, the committee is made up of three officers: a chair,
            secretary, and treasurer, as well as six additional committee
            members.
          </p>

          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card p-3 text-center">
                <h4>Chair</h4>
                <p>
                  Responsible for chairing meetings and providing overall
                  direction.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-3 text-center">
                <h4>Secretary</h4>
                <p>Handles correspondence and records meeting minutes.</p>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card p-3 text-center">
                <h4>Treasurer</h4>
                <p>Manages the Society's finances and budgets.</p>
              </div>
            </div>
          </div>

          <p>
            The committee can be contacted by email at{" "}
            <a href="mailto:chinbrookmeadowsallotments@gmail.com">
              chinbrookmeadowsallotments@gmail.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
}

export default About;
