import React from "react";

function Media() {
  return (
    <div className="media container">
      <section className="hero-section text-center">
        <h1>Media</h1>
      </section>
      <section className="content">
        <p>
          Explore videos showcasing sustainable farming practices, from
          regenerative agriculture to urban farming. These films offer
          inspiration and practical tips for transforming your own garden or
          allotment into a thriving ecosystem focused on soil health,
          biodiversity, and eco-friendly practices.
        </p>
      </section>
      <section>
        <h2>
          <a
            href="https://www.waterbear.com/watch"
            target="_blank"
            rel="noopener noreferrer"
          >
            Waterbear
          </a>
        </h2>
        <p>
          WaterBear is a free streaming platform focused on environmental
          documentaries, covering topics like climate change and sustainable
          practices. Films like{" "}
          <a
            href="https://www.waterbear.com/watch/in-our-hands"
            target="_blank"
            rel="noopener noreferrer"
          >
            In Our Hands (2020)
          </a>{" "}
          and{" "}
          <a
            href="https://www.waterbear.com/watch/the-farm-under-the-city"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Farm Under the City (2021)
          </a>{" "}
          showcase how urban farming can transform city landscapes and promote
          sustainability.
        </p>
        <p>
          With videos ranging from 10 minutes to full-length documentaries,
          WaterBear has content for any schedule, providing both quick insights
          and in-depth exploration of environmental issues.
        </p>
      </section>

      <section>
        <h2>
          <a
            href="https://www.youtube.com/c/Patagonia"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unbroken Ground
          </a>
        </h2>
        <p>
          Patagonia’s YouTube channel offers documentaries that focus on
          environmental activism, sustainable practices, and outdoor
          exploration. Films, like{" "}
          <a
            href="https://www.youtube.com/watch?v=3Ezkp7Cteys"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unbroken Ground (2015)
          </a>
          , highlight regenerative agriculture and its potential to restore soil
          health and combat climate change.
        </p>
        <p>
          Patagonia’s films inspire action and provide critical insights into
          environmental issues, helping viewers understand how they can
          contribute to a healthier planet.
        </p>
      </section>

      <section>
        <h2>
          <a
            href="https://kisstheground.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kiss the Ground
          </a>{" "}
          &{" "}
          <a
            href="https://commongroundfilm.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Common Ground
          </a>
        </h2>
        <p>
          Kiss the Ground (2020) explores regenerative agriculture's potential to
          restore soil health and combat climate change, while sequel Common Ground (2022)
          delves deeper into the movement, showcasing real-life stories of
          communities working to restore their ecosystems.{" "}
          <a
            href="https://www.netflix.com/title/81030633"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kiss the Ground
          </a>{" "}
          and{" "}
          <a
            href="https://www.netflix.com/title/81030634"
            target="_blank"
            rel="noopener noreferrer"
          >
            Common Ground
          </a>{" "}
          can be viewed on Netflix (subscription required).
        </p>
      </section>

      <section>
        <h2>
          <a
            href="https://www.towhichwebelong.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            To Which We Belong
          </a>
        </h2>
        <p>
          To Which We Belong (2020) looks at how regenerative agriculture can
          help restore the planet’s ecosystems. The film follows farmers,
          scientists, and communities around the world who are using sustainable
          farming practices to heal the soil, increase biodiversity, and fight
          climate change. Through personal stories and real-world examples, it
          shows how a shift in our relationship with the land can lead to
          lasting change. You can watch the full film for free on YouTube{" "}
          <a
            href="https://www.youtube.com/watch?v=I6qyLr-WF8I"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </section>

      <section>
        <h2>
          <a
            href="https://www.sixinchesofsoil.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Six Inches of Soil
          </a>
        </h2>
        <p>
          Six Inches of Soil (2023) tells the inspiring story of British farmers
          standing up against the industrial food system and transforming the
          way they produce food—to heal the soil, benefit our health, and
          provide for local communities. You can watch the film on Amazon Prime{" "}
          <a
            href="https://www.amazon.co.uk/Six-Inches-Soil-Colin-Ramsay/dp/B0D9QC7DDG"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          (subscription required).
        </p>
      </section>
    </div>
  );
}

export default Media;
