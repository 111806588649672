import React from "react";
import Photo1 from "../images/cat.jpg";
import Photo2 from "../images/planter.jpg";
import Vetchling from "../images/vetchling.png";
import Fox1 from "../images/wildlife/fox1.jpg";
import Fox2 from "../images/wildlife/fox2.jpg";
import Fox3 from "../images/wildlife/fox3.jpg";
import Toad from "../images/wildlife/toad.jpg";
import Slowworm from "../images/wildlife/slowworm.jpg";

function Conservation() {
  return (
    <div className="conservation container">
      <div className="container">
        {/* Hero Section */}
        <section className="hero-section text-center">
          <h1>Conservation Efforts</h1>
        </section>

        {/* Locations Section */}
        <section>
          <h2>Locations of Interest</h2>
          <p className="conservation-paragraph">
            Maintaining continuous green spaces is essential for wildlife to
            move freely, access resources, and sustain healthy populations. In
            urban areas like the London Borough of Lewisham, green corridors
            play a crucial role in supporting local biodiversity and ecological
            balance. Research shows that urban parks often host greater species
            richness compared to other types of green spaces, underscoring their
            importance in urban planning.
          </p>
          <p>
            Initiatives such as the Green Chain Walk and Waterlink Way highlight
            Lewisham's efforts to create interconnected green spaces that
            benefit both wildlife and residents. Allotments also play a key role
            in the Green Chain, providing habitats for pollinators, birds, and
            small mammals while serving as community green spaces. These
            cultivated areas help maintain the continuity of green corridors,
            supporting wildlife movement and enhancing local biodiversity.
          </p>
        </section>

        <section>
          {/* Location List */}
          <div className="location-list">
            <div className="location-card">
              <h5 className="location-title">Chinbrook Meadows Park</h5>
              <p className="location-description">
                Grasslands, mature trees, rivers, and hedgerows.
              </p>
            </div>
            <div className="location-card">
              <h5 className="location-title">Elmstead Woods</h5>
              <p className="location-description">A vast, ancient woodland.</p>
            </div>
            <div className="location-card">
              <h5 className="location-title">Grove Park Cemetery</h5>
              <p className="location-description">
                A serene space filled with grassland flowers and mature trees.
              </p>
            </div>
            <div className="location-card">
              <h5 className="location-title">Sunridge Park Golf Course</h5>
              <p className="location-description">
                A peaceful park perfect for walks.
              </p>
            </div>
            <div className="location-card">
              <h5 className="location-title">
                Adjacent Wooded Railway Cutting
              </h5>
              <p className="location-description">
                A vital green corridor for wildlife.
              </p>
            </div>
            <div className="location-card">
              <h5 className="location-title">Household Gardens</h5>
              <p className="location-description">
                Beautiful private gardens filled with native plants.
              </p>
            </div>
          </div>
        </section>

        {/* Introductory Paragraph */}
        <section>
          <h2>Local Biodiversity</h2>
          <p>
            The diverse ecosystems in this area support a wide range of flora
            and fauna, from birds to plants. It is essential that we protect and
            preserve these habitats to maintain the balance of life that exists
            here.
          </p>
        </section>

        {/* Biodiversity Cards Section */}
        <section>
          <div className="card-deck">
            <div className="card wildlife-card">
              <div className="card-body">
                <h5 className="card-title">Birds</h5>
                <p className="card-text">
                  This area is home to various bird species, from the melodic
                  Blackcap to the energetic Blue Tit. You'll likely spot
                  Buzzards soaring overhead, while Carrion Crows and Coal Tits
                  keep busy around the area.
                </p>
              </div>
            </div>

            <div className="card wildlife-card">
              <div className="card-body">
                <h5 className="card-title">Mammals</h5>
                <p className="card-text">
                  Foxes are a prominent sight here, while smaller creatures like
                  Field Mice and Wood Mice are often scurrying about. Hedgehogs
                  and Brown Rats also make their homes here.
                </p>
              </div>
            </div>

            <div className="card wildlife-card">
              <div className="card-body">
                <h5 className="card-title">Reptiles & Amphibians</h5>
                <p className="card-text">
                  From the basking Common Lizard to the elusive Slow Worm, this
                  habitat is rich with reptile and amphibian life, including
                  Common Frogs and Toads that thrive in the wetland areas.
                </p>
              </div>
            </div>

            <div className="card wildlife-card">
              <div className="card-body">
                <h5 className="card-title">Invertebrates</h5>
                <p className="card-text">
                  The area is alive with butterflies, including the Gatekeeper
                  and Brimstone. You'll also find Meadow Brown Butterflies and
                  the striking Red Admiral.
                </p>
              </div>
            </div>

            <div className="card wildlife-card">
              <div className="card-body">
                <h5 className="card-title">Rare/Local Plants</h5>
                <p className="card-text">
                  Among the rare plants, you'll find Grass Vetchling and Wild
                  Rose, along with Meadow Vetchling and Hairy Tare, adding to
                  the area's biodiversity.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Photo Gallery */}
        <section>
          <h3 className="subheading">Wildlife Gallery</h3>
        </section>
        <section>
          <div
            id="photoCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={Fox1}
                  className="d-block w-50 mx-auto rounded"
                  alt="Fox 1"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Fox2}
                  className="d-block w-50 mx-auto rounded"
                  alt="Fox 2"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Fox3}
                  className="d-block w-50 mx-auto rounded"
                  alt="Fox 3"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Toad}
                  className="d-block w-50 mx-auto rounded"
                  alt="Toad"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Slowworm}
                  className="d-block w-50 mx-auto rounded"
                  alt="Slowworm"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Photo1}
                  className="d-block w-50 mx-auto rounded"
                  alt="Cat"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Photo2}
                  className="d-block w-50 mx-auto rounded"
                  alt="Planter"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={Vetchling}
                  className="d-block w-50 mx-auto rounded"
                  alt="Vetchling"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#photoCarousel"
              data-bs-slide="prev"
            >
              &#8249;
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#photoCarousel"
              data-bs-slide="next"
            >
              &#8250;
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Conservation;
