function Location() {
  return (
    <section className="location container">
      <div className="container">
        <section className="hero-section text-center">
          <h1>Location</h1>
        </section>
        <p className="text-center">
          Please note, everyday access to the site is restricted to allotment
          members.
        </p>
        <div className="row">
          <section className="col-md-4">
            <div className="info-card">
              <h2 className="section-title">
                Driving/Cycling <i className="fa-solid fa-bicycle"></i>
                <i className="fa-solid fa-car"></i>
              </h2>
              <p>
                If using satellite navigation, target Grove Park Cemetery
                (Marvels Lane, London SE12 9PU). Continue past the cemetery
                entrance and you will reach the entrance gate to the allotment
                site. Free car parking spaces are available to the left of the
                allotment entrance gate. Bicycle racks are also available for
                use inside the site.
              </p>
            </div>
          </section>

          <section className="col-md-4">
            <div className="info-card">
              <h2 className="section-title">
                Bus <i className="fa-solid fa-bus-simple"></i>
              </h2>
              <p>
                The local area is served by the following buses: 284, 124, 126,
                273, and 624.
              </p>
              <p>
                Bus stop locations can be checked on the{" "}
                <a
                  href="https://tfl.gov.uk/maps/bus?Input=Grove+Park+Cemetery%2C+Marvels+Lane%2C+London%2C+UK&InputGeolocation=51.42462158203125%2C0.03640680015087128"
                  target="_blank"
                  rel="noreferrer"
                >
                  Transport for London (TfL) website.
                </a>
              </p>
            </div>
          </section>

          <section className="col-md-4">
            <div className="info-card">
              <h2 className="section-title">
                Train <i className="fa-solid fa-train"></i>
              </h2>
              <p>
                The allotment site can be reached on foot from Grove Park train
                station in approximately 20 minutes. There are two walking
                routes: (1) via Chinbrook Meadows recreational ground or (2) via
                Chinbrook Road and Marvels Lane.
              </p>
            </div>
          </section>
        </div>
        <section className="map-section">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.7141168571193!2d0.02828597327935258!3d51.42667800179391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9548b5c2aa7a6fd!2sChinbrook%20allotment%20meadow!5e0!3m2!1sen!2suk!4v1664207319119!5m2!1sen!2suk"
            width="100%"
            height="300"
            loading="lazy"
            title="Location map of Chinbrook allotment meadow"
            style={{ border: 0 }}
          ></iframe>
        </section>
        <div className="map-bottom-space"></div>
      </div>
    </section>
  );
}

export default Location;
