import React from "react";
import Photo1 from "../images/gallery/1.jpg";
import Photo2 from "../images/gallery/2.jpg";
import Photo3 from "../images/gallery/3.jpg";
import Photo4 from "../images/gallery/4.jpg";
import Photo5 from "../images/gallery/5.jpg";
import Photo6 from "../images/gallery/6.jpg";
import Photo7 from "../images/gallery/7.jpg";
import Photo8 from "../images/gallery/8.jpg";
import Photo9 from "../images/gallery/9.jpg";
import Photo10 from "../images/gallery/10.jpg";
import Photo11 from "../images/gallery/11.jpg";
import Photo12 from "../images/gallery/12.jpg";
import Photo13 from "../images/gallery/13.jpg";
import Photo14 from "../images/gallery/14.jpg";
import Photo15 from "../images/gallery/15.jpg";
import Photo16 from "../images/gallery/16.jpg";
import Photo17 from "../images/gallery/17.jpg";
import Photo18 from "../images/orchard-sunset.jpg";
import Photo19 from "../images/orchard-snow.jpg";
import Halloween from "../images/halloween.jpg";
import Photo22 from "../images/gallery/22.jpg";
import Photo23 from "../images/gallery/23.jpg";
import Photo24 from "../images/gallery/24.jpg";
import Photo25 from "../images/gallery/25.jpg";

import "bootstrap/dist/css/bootstrap.min.css";

function Gallery() {
  const photos = [
    Photo18, // Orchard sunset
    Photo19, // Orchard snow
    Photo1,
    Photo2,
    Photo3,
    Photo4,
    Photo5,
    Photo6,
    Photo7,
    Photo8,
    Photo9,
    Photo10,
    Photo11,
    Photo12,
    Photo13,
    Photo14,
    Photo15,
    Photo16,
    Photo17,
    Halloween,
    Photo22,
    Photo23,
    Photo24,
    Photo25,
  ];

  return (
    <div className="gallery container">
      <div className="container">
        <section className="hero-section">
          <h1 className="text-center">Photo Gallery</h1>
        </section>

        <section className="carousel-section">
          <div className="container">
            <div className="row align-items-center">
              <div
                id="photoCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {photos.map((photo, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <div className="d-flex justify-content-center">
                        <img
                          src={photo}
                          className="d-block"
                          style={{
                            height: "50vh",
                            width: "auto",
                            borderRadius: "15px",
                          }}
                          alt={`gallery ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#photoCarousel"
                  data-bs-slide="prev"
                >
                  &#8249; {/* Left arrow icon */}
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#photoCarousel"
                  data-bs-slide="next"
                >
                  &#8250; {/* Right arrow icon */}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Gallery;
