import React from "react";

function Links() {
  const linkCategories = [
    {
      title: "Allotments Information",
      icon: "fa-person-digging",
      links: [
        {
          href: "https://www.nsalg.org.uk/",
          text: "National Allotment Society",
        },
        {
          href: "https://www.rhs.org.uk/advice/grow-your-own/allotments",
          text: "RHS Allotments",
        },
        {
          href: "https://www.gov.uk/apply-allotment",
          text: "Apply for an allotment (Nationally - UK)",
        },
        {
          href: "https://lewisham.gov.uk/myservices/environment/allotments/apply-for-an-allotment",
          text: "Apply for an allotment (Lewisham Borough Residents)",
        },
        {
          href: "https://lewisham.gov.uk/myservices/environment/allotments",
          text: "Lewisham allotments and community gardens",
        },
        {
          href: "https://lewisham.gov.uk/myservices/environment/~/link.aspx?_id=21A693D64ED14F3EB2EE870B6B4B42B9&_z=z",
          text: "Lewisham allotment sites and waiting times",
        },
        { href: "https://www.gardenorganic.org.uk/", text: "Garden Organic" },
      ],
    },
    {
      title: "CCO Community Partnerships",
      icon: "fa-handshake",
      links: [
        {
          href: "https://chinbrookmeadows.wordpress.com/",
          text: "Friends of Chinbrook Meadows",
        },
        {
          href: "https://www.marvelslane.lewisham.sch.uk/",
          text: "Marvels Lane Primary School",
        },
        { href: "https://woodcraft.org.uk/", text: "Woodcraft Folk" },
        { href: "https://chartbiglocal.org.uk/", text: "ChART Big Local" },
        {
          href: "https://natureconservationlewisham.co.uk/how-to/natures-gym-2/",
          text: "Nature's Gym",
        },
        {
          href: "https://natureconservationlewisham.co.uk/",
          text: "Nature Conservation Lewisham",
        },
        {
          href: "https://www.theorchardproject.org.uk/what-we-do/project-a-celebration-of-orchards/orchard-profiles/chinbrook-community-orchard/",
          text: "The Orchard Project",
        },
        {
          href: "https://www.froglife.org/london-blue-chain/",
          text: "London Blue Chain - Froglife",
        },
      ],
    },
    {
      title: "Gardening Tips",
      icon: "fa-lightbulb",
      links: [
        {
          href: "https://couponfollow.com/research/home-gardening-ideas",
          text: "Home Gardening Ideas",
        },
      ],
    },
    {
      title: "Orchard Information",
      icon: "fa-apple-whole",
      links: [
        {
          href: "http://www.nationalfruitcollection.org.uk",
          text: "National Fruit Collection",
        },
        { href: "https://www.keepers-nursery.co.uk", text: "Keepers Nursery" },
        { href: "https://www.fruitid.com/#main", text: "FruitID" },
        {
          href: "https://www.orangepippin.com/varieties/apples/coxs-orange-pippin",
          text: "Orange Pippin",
        },
        { href: "https://tomtheappleman.co.uk", text: "Tom The Apple Man" },
        { href: "https://www.frankpmatthews.com", text: "Frank P Matthews" },
      ],
    },
    {
      title: "Seeds",
      icon: "fa-seedling",
      links: [
        { href: "https://www.realseeds.co.uk/", text: "Real Seeds" },
        { href: "https://vitalseeds.co.uk/", text: "Vital Seeds" },
        {
          href: "https://www.ethicalorganicseeds.co.uk/",
          text: "Ethical Organic Seeds",
        },
        { href: "https://www.growseed.co.uk/", text: "Growseed" },
        { href: "https://tamarorganics.co.uk/", text: "Tamar Organics" },
        {
          href: "https://www.organicseedspecialists.co.uk/",
          text: "Organic Seed Specialists",
        },
        {
          href: "https://www.organicpotatoes.co.uk/",
          text: "Leary's Organic Seeds",
        },
        {
          href: "https://britishwildflowermeadowseeds.co.uk/",
          text: "British Wildflower Seeds",
        },
        { href: "https://seedcooperative.org.uk/", text: "Seed Co-operative" },
        {
          href: "https://www.openpollinated.co.uk/",
          text: "Heirloom & Perennial",
        },
        { href: "https://www.greenmanure.co.uk/", text: "Green Manure" },
        { href: "https://learntosaveseeds.com/", text: "How to save seeds" },
      ],
    },
    {
      title: "Wildlife & Conservation",
      icon: "fa-dove",
      links: [
        {
          href: "https://www.wildlifetrusts.org/gardening",
          text: "The Wildlife Trusts",
        },
        {
          href: "https://www.rspb.org.uk/birds-and-wildlife/advice/gardening-for-wildlife/",
          text: "RSPB",
        },
        {
          href: "https://www.wildaboutgardens.org.uk/",
          text: "Wild About Gardens",
        },
        {
          href: "https://www.rhs.org.uk/gardening-for-the-environment/planet-friendly-gardening-tips",
          text: "RHS - 10 ways to be more sustainable in your garden",
        },
        {
          href: "https://www.rhs.org.uk/science/conservation-biodiversity/wildlife/encourage-wildlife-to-your-garden",
          text: "RHS - Encourage wildlife to your garden",
        },
        {
          href: "https://www.nationalparkcity.london/",
          text: "London National Park City",
        },
        {
          href: "https://www.woodlandtrust.org.uk/blog/2019/09/how-to-build-a-bug-hotel/",
          text: "Woodland Trust - how to build a bug hotel",
        },
        {
          href: "https://www.bto.org/how-you-can-help/providing-birds/putting-nest-boxes-birds/make-nest-box",
          text: "BTO - Make a nest box",
        },
      ],
    },
  ];

  return (
    <div className="links container my-5">
      <div className="hero-section text-center mb-4">
        <h1 className="mb-4">Useful Links</h1>
      </div>
      {linkCategories.map((category) => (
        <section key={category.title}>
          <h2 className="mb-3">
            {category.title} <i className={`fa-solid ${category.icon}`}></i>
          </h2>
          <div className="row g-4">
            {category.links.map((link) => (
              <div
                key={link.text}
                className="col-12 col-sm-6 col-md-4 col-lg-3"
              >
                <div className="card h-100 shadow border-0">
                  <div className="card-body d-flex flex-column justify-content-between">
                    <h5 className="card-title text-dark">{link.text}</h5>
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-green mt-3"
                    >
                      Visit <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
}

export default Links;
