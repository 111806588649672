import React from "react";
import Photo1 from "../images/allotment-min.jpg";
import Photo2 from "../images/allotment-sunset.jpg";
import Photo3 from "../images/orchard-hut.jpg";

function Home() {
  return (
    <div className="home">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${Photo1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          color: "#fff",
          textAlign: "center",
          padding: "100px 20px",
        }}
      >
        {/* Overlay */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark overlay
            zIndex: 1,
          }}
        ></div>

        {/* Text Content */}
        <div
          style={{
            position: "relative",
            zIndex: 2,
          }}
        >
          <h1
            style={{
              color: "#fff", // Ensure visibility
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.8)", // Add subtle text shadow
            }}
          >
            Chinbrook Meadows Horticultural Society
          </h1>
          <p
            style={{
              color: "#f0f0f0",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.8)",
            }}
          >
            A self-managed horticultural society in South East London, fostering
            community, well-being, and a love for nature.
          </p>
        </div>
      </section>

      {/* About Section */}
      <section>
        <div className="container">
          <p>
            Chinbrook Meadows Horticultural Society is located in the London
            Borough of Lewisham. Formerly farmland as part of the Baring Estate,
            the area became part of Chinbrook Meadows in the early 20th century.
            The allotments, established on the southern part of Chinbrook
            Meadows, now include a nature reserve and community orchard created
            in 1991.
          </p>
        </div>
      </section>

      {/* Photo Gallery */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img
                src={Photo1}
                alt="Allotment"
                className="img-fluid rounded mb-3"
              />
            </div>
            <div className="col-md-4">
              <img
                src={Photo2}
                alt="Allotment Sunset"
                className="img-fluid rounded mb-3"
              />
            </div>
            <div className="col-md-4">
              <img
                src={Photo3}
                alt="Orchard Hut"
                className="img-fluid rounded mb-3"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Purpose Section */}
      <section>
        <div className="home container">
          <h2>Our Purpose</h2>
          <div className="row">
            {[
              "Gardening: cultivating flowers, fruit and vegetables solely for the consumption of family and friends.",
              "Social Capital: to ameliorate loneliness and enable citizens to contribute to society.",
              "Mental well-being: gardening plays a role in both preventing and alleviating mental ill-health.",
              "Physical activity: helping plot-holders to keep fit.",
              "Fresh, local, seasonal produce: giving access to fresh fruit and vegetables over the year.",
              "Sense of achievement: growing vegetables requires acquiring new knowledge and skills.",
              "Contact with nature: experiencing the seasons and gaining an understanding of the eco-system.",
              "Provide habitats for local species: protecting and improving existing habitats, particularly the orchard.",
            ].map((item, index) => (
              <div key={index} className="col-md-6 col-lg-4 mb-3">
                <div
                  className="card h-100 border-0 shadow-sm p-3"
                  style={{
                    backgroundColor: "#f7f9fc", // Light blueish-grey background
                    border: "1px solid #d1d9e6", // Subtle grey border
                    borderRadius: "8px",
                  }}
                >
                  <p style={{ color: "#333" }}>{item}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
