import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-light fixed-bottom footer">
      <div
        className="container d-flex justify-content-between align-items-center py-2"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        {/* Social Icons */}
        <div className="social-icons">
          <a
            href="https://www.facebook.com/ChinbrookMeadows/"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <i className="fa-brands fa-facebook icon"></i>
          </a>
          <a
            href="https://www.instagram.com/cc_orchard/"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <i className="fa-brands fa-instagram icon"></i>
          </a>
          <a
            href="mailto:chinbrookmeadowsallotments@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <i className="fa-regular fa-paper-plane icon"></i>
          </a>
        </div>

        {/* Footer Text */}
        <p className="m-0 text-muted footer-text">
          &copy; {currentYear} Chinbrook Meadows Horticultural Society. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
